<template>
  <importWindow ref="popupRef">
    <template v-slot:popup>
      <fflogsImport @close-popup="popupRef.closePopup()" @fflogs-import="handleFFLogsImport" />
    </template>
    <template v-slot:info>
      <div class="infoTitle">
        <span style="font-family:'Bebas Neue';">FFlogs import</span>
      </div>
      <div class="infoLine"></div>
      <div class="infoText" style="width:100%;">
        This tool allows you to import a cropped sequence from FFLogs. Simply do the following: <br><br>
        <ol>
          <li>Find a log containing the sequence you want to import on FFLogs.</li>
          <li>Select the player whose actions you wish to import.</li>
          <li>Crop the timeline on FFLogs to the segment you wish to import.</li>
          <li>Copy the URL of the log, and paste it here.</li>
        </ol>
        The URL must contain the start time, end time, and actor(source). Excessively long timelines are liable to have a cursed appearance, so keep the sequences short.
      </div>
    </template>
  </importWindow>
  <div class="infoPane" v-if="infoOpen">
    <div id="innerInfoBorder"></div>
    <div class="infoContainer">
      <img @click="toggleInfoPane" id="infoLocus" src="/images/icons/logo.png" alt="logo" />
    </div>
    <div class="infoTitle">
      <span style="font-family:'Bebas Neue';">about</span>
    </div>
    <div class="infoLine"></div>
    <div class="infoText">This is a small personal project. There are many tools like this already, but I wanted to create
      something that would suit my particular use case. It is currently in Alpha, and can be expected to exhibit erratic
      and otherwise imperfect behavior. The underlying data is webscraped, and liable to contain errors. Please let me
      know if you find any at locussolace@gmail.com.
      <br><br>
      Tips: <br>
      -Double Click an action to add or remove it from the sequence.<br>
      -Sequences can be shared via URL, or exported as an image or CSV.<br>
      -Sequences can be saved and recalled via the preset menu. <br>
    </div>
    <div class style="text-align:right; width:50%; margin:auto; margin-top:30px;">-Locus</div>

  </div>
  <transition name="slide">
    <div class="slideMenu" v-if="isOpen">
      <div class="cogSpacer">
        <span id="slideMenuGearCog" class="material-symbols-outlined">settings</span>
        <span @click="toggleInfoPane" id="slideMenuInfoButton" class="material-symbols-outlined">info</span>
      </div>
      <div id="options-inner-container">
        <span>Visuals</span>
        <div class="settingsLine"> </div>
        <div id="colorContainer">
          <button class=" colorselect-button" :class="{ colorActive: selectedColor.color === color.color }"
            :style="{ background: color.color }" v-for="(color, index) in colorPresets" :key="index"
            @click="selectedColor = color"></button>
        </div>
        <Toggle v-model="darkMode" @change="emit('toggle-darkmode', darkMode)" on-label="Dark" off-label="Light"
          class="modeToggle" />
        <br>

        <div v-if="ioActiveTab == 'export'">
          <div @click="ioActiveTab = 'import'" style="position:relative;"><span
              style="margin-top:100px;">Export</span><span class="material-symbols-outlined"
              style="position:absolute; right:85px; top:5px;">chevron_right</span></div>

          <div class="settingsLine"> </div>
          <div id="exportContainer">
            <button class="functionalButton" @click="exportAsPNG">png</button>
            <button class="functionalButton" @click="exportAsCSV">csv</button>
          </div>
        </div>
        <div v-if="ioActiveTab == 'import'">
          <div @click="ioActiveTab = 'export'" style="position:relative;"><span class="material-symbols-outlined "
              style="position:absolute; left:85px; top:5px;">chevron_left</span><span
              style="margin-top:100px;">import</span></div>
          <div class="settingsLine"> </div>
          <div id="importContainer">
            <button class="functionalButton" @click="popupRef.openPopup(); isOpen = false">FFlogs</button>
          </div>
        </div>


      </div>
      <div @click="isOpen = false" class="cogSpacer">
        <span id="closeMenuButton" class="material-symbols-outlined">chevron_right</span>
        <p id="versionNumber">v.03a</p>
      </div>
    </div>
  </transition>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script setup>
import html2canvas from 'html2canvas';
import { ref, defineExpose, defineProps, watch, defineEmits, onMounted } from 'vue';
import Toggle from '@vueform/toggle'
import importWindow from './primitives/fullscreenPopup.vue';
import fflogsImport from './fflogsImport.vue';
const iconShadow = ref(true);
const isOpen = ref();
const infoOpen = ref(false);
const doneLoading = ref(false);
const popupRef = ref(null);
const ioActiveTab = ref('export');



//function to toggle infoOpen
const toggleInfoPane = () => {
  infoOpen.value = !infoOpen.value;
};
const selectedColor = ref({ color: '#ff6a00', lightColor: '#ff9500', gradient: 'linear-gradient(to right, #ff6a00, #ff9500)' });

const props = defineProps({
  yuckyMeals: Array,

});



onMounted(async () => {
  if (localStorage.getItem('darkMode')) {
    darkMode.value = JSON.parse(localStorage.getItem('darkMode'));
  }
  if (localStorage.getItem('selectedColor')) {
    selectedColor.value = JSON.parse(localStorage.getItem('selectedColor'));
  }
  doneLoading.value = true;
});

const darkMode = ref(false);

watch(darkMode, (newValue) => {
  if (doneLoading.value) {
    localStorage.setItem('darkMode', newValue);
  }
}, { deep: true, immediate: true });



const colorPresets = [
  { color: '#d60202', lightColor: '#f63232', gradient: 'linear-gradient(to right, #d60202, #f63232) ' },
  { color: '#ff6a00', lightColor: '#ff9500', gradient: 'linear-gradient(to right, #ff6a00, #ff9500)' },
  { color: '#f5ca12', lightColor: '#f6ec32', gradient: 'linear-gradient(to right, #f5ca12, #f6ec32) ' },
  { color: '#03d10f', lightColor: '#19e936', gradient: 'linear-gradient(to right, #03d10f, #19e936) ' },
  { color: '#00ffff', lightColor: '#00ffea', gradient: 'linear-gradient(to right, #00ffff, #00ffea)' },
  { color: '#0084ff', lightColor: '#00a2ff', gradient: 'linear-gradient(to right, #0084ff, #00a2ff)' },
  { color: '#7f24e2', lightColor: '#b243eb', gradient: 'linear-gradient(to right, #7f24e2, #b243eb) ' },
  { color: '#f93962', lightColor: '#ff6492', gradient: 'linear-gradient(to right, #f93962, #ff6492) ' },
  { color: '#696666', lightColor: '#696666', gradient: 'linear-gradient(to right, #464444, #696666) ' },
]
const emit = defineEmits(['update-color', 'toggle-darkmode', 'fflogs-import']);
watch(selectedColor, (newValue) => {
  if (doneLoading.value) {
    localStorage.setItem('selectedColor', JSON.stringify(newValue));
  }
  emit('update-color', newValue);
});

defineExpose({ isOpen, darkMode, iconShadow });


const exportAsPNG = async () => {
  try {
    iconShadow.value = false;
    await setTimeout(async () => {
      const element = document.getElementById('sequenceWrapper');
      const canvas = await html2canvas(element, { backgroundColor: null, allowTaint: true, });
      const image = canvas.toDataURL("image/png");
      const link = document.createElement('a');
      link.download = 'exported-image.png';
      link.href = image;
      link.click();
    }, 200);

  } catch (error) {
    iconShadow.value = true;
    console.error('Error exporting image:', error);
  }
  setTimeout(() => {
    iconShadow.value = true;
  }, 200);
};
const exportAsCSV = async () => {
  const header = ['actionName', 'recast'];
  const csvRows = [header.join(',')];
  props.yuckyMeals.forEach(meal => {
    const row = [meal.actionName, meal.recast].join(',');
    csvRows.push(row);
  });
  const csvContent = `data:text/csv;charset=utf-8,${csvRows.join('\n')}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'ability-string.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleFFLogsImport = (payload) => {
  emit('fflogs-import', payload);
};


</script>
  
  
  
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.slideMenu {
  position: fixed;
  top: calc(50% - 244px);
  width: 300px;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px 0px 0px 10px;
  
}

.infoPane {
  position: fixed;
  top: calc(50% - 300px);
  left: calc(50% - 300px);
  width: 600px;
  height: 600px;
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23565656' fill-opacity='0.31' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E");
  z-index: 1000;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-self: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10%;
}

.infoContainer {
  display: block;
  margin-top: 40px;
  justify-content: center;
  /* Center horizontally */
}

#infoLocus {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

#innerInfoBorder {
  position: absolute;
  width: 95%;
  height: 95%;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 10%;
  border: 3px solid white;
  pointer-events: none;
}

.infoTitle {
  width: 100%;
  font-size: 50px;
  color: white;
  text-align: center;
  line-height: 1.5;
}

.infoText {
  font-family: 'Roboto', sans-serif;
  width: 85%;
  margin: auto;
  margin-top: 10px;
  font-size: 15px;
  text-justify: inter-word;
  text-align: justify;

}

.infoLine {
  width: 45%;
  margin: auto;
  height: 1px;
  background-color: white;
}


.settingsLine {
  width: 45%;
  margin: 0px auto 10px auto;
  height: 1px;
  background-color: white;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

#options-inner-container {
  width: 100%;
  margin: auto;
}



#colorContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100px;
  margin: auto;

}

.colorselect-button {
  width: 100%;
  /* Makes the button fill the grid cell */
  height: 0;
  padding-bottom: 100%;
  /* This makes the height equal to the width, creating a square */
  border: none;
  border-radius: 50%;
  /* Makes the button circular */
  box-shadow: inset 0 0 0 2px white;
  /* box-sizing:border-box; */
  opacity: 0.6;
}

.colorselect-button.colorActive {
  opacity: 1;
}

.colorselect-button:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.functionalButton {
  width: 46%;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: none;
  border-radius: 5px;
  margin-right: 1%;
  margin-left: 1%;
}

#exportContainer {
  width: 45%;
  margin: auto;
}

#slideMenuInfoButton {
  position: absolute;
  left: 5px;
  top: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.modeToggle {
  --toggle-bg-on: rgb(46, 46, 46);
  --toggle-border-off: transparent;
  --toggle-border-on: transparent;
  --toggle-ring-color: transparent;
  --toggle-handle-enabled: #666666;
  --toggle-font-size: 80%;
  --toggle-width: 100px;
  --toggle-height: 20px;
}

.toggle-container {
  margin-top: 20px;
  margin-bottom: 20px;
}



.functionalButton:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease-in-out;
}

#slideMenuGearCog {
  margin: auto;
  line-height: 2;
  font-size: 2rem;
}

#closeMenuButton {
  margin: auto;
  line-height: 2;
  font-size: 2rem;
  cursor: pointer;
}

#versionNumber {
  position: absolute;
  left: 10px;
  bottom: 0px;
  margin: 0;
  font-size: 0.8rem;
}

.cogSpacer {
  display: block;
  height: 4rem;
  position: relative;
}

@media(max-width:480px) {
  .infoPane {
    top: calc(50% - 240px);
    left: calc(50% - 180px);
    width: 360px;
    height: 480px;
  }

  .infoTitle {
    font-size: 25px;

  }

  .infoText {
    font-family: 'Roboto', sans-serif;
    width: 85%;
    margin: auto;
    margin-top: 10px;
    font-size: 13px;
    text-justify: inter-word;
    text-align: justify;

  }
}
</style>