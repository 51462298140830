//ContextMenu.vue
<template>
  <div class="context-menu" :style="{ top: y + 'px', left: x + 'px' }">
    <div v-for="action in actions" :key="action.action" @click="emitAction(action.action)">
      {{ action.label }}
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, defineEmits } from 'vue';

const { actions, x, y } = defineProps(['actions', 'x', 'y']);
const emit = defineEmits(['action-clicked']);

const emitAction = (action) => {
  emit('action-clicked', action);
};
</script>

<style scoped>
.context-menu {
  position: absolute;
  min-width: 150px;
  z-index: 1000;
  color: white;
}

.context-menu div {
  cursor: pointer;
  user-select: none;
  padding-left: 5px;
  padding-right: 5px;
}

.context-menu div:hover {}
</style>