<template>
  <div id="sequenceWrapperWrapper">
    <span id="trashCan" v-on:click="localYuckyMeals.splice(0, localYuckyMeals.length);">clear</span>
    <draggable v-model="localYuckyMeals" tag="div" :group="{ name: 'meals' }" :animation="300" id="sequenceWrapper"
      item-key="id" @start="startedDragging" @end="stoppedDragging">
      <template #item="{ element: meal, index }">
        <div :class="{ 'sequencer-icon-frame': meal.gcd === true, 'sequencer-shadow': props.shadow == true}">
          <img :src="meal.iconPath" :alt="meal.actionName" @dblclick="removeMeal(index)"
            :class="{ 'gcd': meal.gcd === true, 'ogcd': meal.gcd === false }" @mouseenter="actionMouseEnter(meal)"
            @mouseleave="actionMouseLeave(meal)" />
        </div>
      </template>
    </draggable>
    <div id="trashCan" v-on:click="localYuckyMeals.splice(0, localYuckyMeals.length);">clear</div>
    <div v-if="isDragging" id="trashWrapper">
      <draggable v-model="trash" tag="div" :group="{ name: 'meals' }" :animation="300" id="trashDraggable" item-key="id">
        <template #item="{ element: meal, }">
          <div> {{ meal.name }}</div>
        </template>
      </draggable>
      <span id="trashIcon" class="material-symbols-outlined keyColorDark">delete</span>
    </div>
  </div>
</template>

<script setup>
import draggable from 'vuedraggable';
import { ref, watch } from 'vue';
import { defineProps, defineEmits } from 'vue';

const isDragging = ref(false);
// 

const props = defineProps({
  yuckyMeals: Array,
  shadow: Boolean,
});

const startedDragging = () => {
  isDragging.value = true;
};
const stoppedDragging = () => {
  isDragging.value = false;
};


const localYuckyMeals = ref([...props.yuckyMeals]);
const trash = ref([])


watch(() => props.yuckyMeals, (newValue) => {
  localYuckyMeals.value = newValue;
}, { deep: true, immediate: true });

watch(localYuckyMeals, (newValue) => {
  emit('update-meals', newValue);
  trash.value = [];
}, { deep: true });



const emit = defineEmits(['remove-meal', 'update-meals', 'actionMouseEnter', 'actionMouseLeave']);



function removeMeal(index) {
  emit('remove-meal', index);
}

function actionMouseEnter(item) {
  emit('actionMouseEnter', item);
}

function actionMouseLeave(item) {
  emit('actionMouseLeave', item);
}

</script>
  
<style scoped>
#sequenceWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 0;
  width: 100%;
  height: 30%;
  /* border: 5px solid rgb(117, 58, 255); */
  border-radius: 10px;
  padding: 5px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 30px;
  min-height: 120px;
  row-gap: 25px;
}

#sequenceWrapperWrapper {
  position: relative;
}



#sequenceWrapperWrapper::after {
  content: '';
  position: absolute;
  top: -30px;
  /* Adjust as needed */
  right: 35px;
  /* Align with the trashCan position */
  width: 80px;
  /* Width of the tab, adjust as needed */
  height: 31px;
  /* Height of the tab, adjust as needed */
  border-radius: 15px 15px 0px 0px;
  /* Rounded bottom corners */
  z-index: 500;
  /* Ensure it's below the trashCan */
}

#trashCan {
  position: absolute;
  right: 53px;
  top: -20px;
  line-height: 55%;
  font-size: 1rem;
  z-index: 1000;
}

#trashDraggable {
  height: 50px;
  width: 50px;
  z-index: 1500;
  position: absolute;
  bottom: -50px;
  right: 10px;
}

#trashWrapper {
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}

#trashIcon {
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: -50px;
  right: -10px;
  font-size: 50px;
}



.ogcd {
  max-width: 40px;
  max-height: 40px;
  margin: 0;
  border-radius: 50%;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.gcd {
  height: 100%;
  width: 100%;
}

.sequencer-icon-frame {
  max-width: 64px;
  margin: 5px;
  position: relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

}

.sequencer-icon-frame::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  background-image: url(/public/images/icons/bevelframe.png);
  background-size: contain;
  width: 100%;
  height: auto;
  pointer-events: none;
  border-radius: 5px;
}

.sequencer-icon-frame.sequencer-shadow::after {
  box-shadow: 0 0 10px #000000;
}


.sequencer-icon-frame:hover::after {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
  /* box shadow glow effect */
  box-shadow: 0 0 10px #000000;
}

.ogcd:hover,
.gcd:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}



@media(max-width:480px) {
  #sequenceWrapper {
    min-height: 50px;
  }



  .sequencer-icon-frame {
    max-width: 32px;
    max-height: 32px;
  }

  .ogcd {
    max-width: 20px;
    max-height: 20px;
  }



}

@media(max-width:1000px) {

  #trashDraggable {
    right: -30px;
  }



  #trashIcon {

    right: -30px;
  }

}

/* Tablet styles */
@media(min-width:481px) and (max-width:800px) {

  .sequencer-icon-frame {
    max-width: 32px;
    max-height: 32px;
  }


  .ogcd {
    max-width: 20px;
    max-height: 20px;
  }

  #sequenceWrapper {
    min-height: 50px;
  }

}
</style>
  