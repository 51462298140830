
<script setup>

import { useSeoMeta } from '@unhead/vue'

useSeoMeta({
  title: 'XIV Action Sequencer',
  ogTitle: 'XIV Action Sequencer',
  description: 'Rudimentary FFXIV action sequencer with dnd interface and shareble urls',
  ogDescription: 'Rudimentary FFXIV action sequencer with dnd interface and shareble urls',
})

import { ref, computed, watch, onMounted, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import gcdsComponent from './gcdsComponent.vue';
import sequenceTimeline from './sequenceTimeline.vue';
import tooltipFrame from './tooltipFrame.vue';
import settingsMenu from './settingsMenu.vue';
import CustomDropdown from './customDropdown.vue';
import presetsMenu from './presetsMenu.vue';
import quickPresets from './quickPresets.vue';

const optionSets = [
  { name: 'Tank', iconPath: '/images/icons/Tank_Icon_Flat_1.png', backgroundPath: '/images/bLOy02Y0Yqaow2a-StUgi24q-c-min.png', options: [{ value: 'paladin', label: 'Paladin' }, { value: 'warrior', label: 'Warrior' }, { value: 'gunbreaker', label: 'Gunbreaker' }, { value: 'darkknight', label: 'Dark Knight' }] },
  { name: 'Healer', iconPath: '/images/icons/Healer_Icon_Flat_1.png', backgroundPath: '/images/baIUM7cUhYOmrGslel9WQkrNEY-min.png', options: [{ value: 'whitemage', label: 'White Mage' }, { value: 'scholar', label: 'Scholar' }, { value: 'astrologian', label: 'Astrologian' }, { value: 'sage', label: 'Sage' }] },
  { name: 'Melee', iconPath: '/images/icons/Melee_DPS_Icon_Flat_1.png', backgroundPath: '/images/WpQmZLXjN_WrkJX968CK4YqEBY-min.png', options: [{ value: 'monk', label: 'Monk' }, { value: 'dragoon', label: 'Dragoon' }, { value: 'ninja', label: 'Ninja' }, { value: 'samurai', label: 'Samurai' }, { value: 'reaper', label: 'Reaper' }] },
  { name: 'Caster', iconPath: '/images/icons/Magic_Ranged_DPS_Icon_Flat_1.png', backgroundPath: '/images/WpQmZLXjN_WrkJX968CK4YqEBY-min.png', options: [{ value: 'blackmage', label: 'Black Mage' }, { value: 'summoner', label: 'Summoner' }, { value: 'redmage', label: 'Red Mage' },] },
  { name: 'Ranged', iconPath: '/images/icons/Physical_Ranged_DPS_Flat_Icon_1.png', backgroundPath: '/images/WpQmZLXjN_WrkJX968CK4YqEBY-min.png', options: [{ value: 'bard', label: 'Bard' }, { value: 'machinist', label: 'Machinist' }, { value: 'dancer', label: 'Dancer' }] },
];


const route = useRoute();
const router = useRouter();
let gcds = ref([]);
let ogcds = ref([]);
let roleActions = ref([]);
const yuckyMeals = ref([]);
const sequenceLength = computed(() => yuckyMeals.value.length)
let isInitialLoad = true;
let tooltipObject = ref({});
const selectedOption = ref(0); // Default selection
const actionData = ref([]);
const dropdown = ref(null);
const currentSetIndex = ref(0); // Index of the currently selected set
const currentOptions = computed(() => optionSets[currentSetIndex.value].options);
const presetRef = ref(null);
const userPresets = ref([]);
let darkMode = computed(() => {
  return menuRef.value ? menuRef.value.darkMode : false;
});
let iconShadow = computed(() => {
  return menuRef.value ? menuRef.value.iconShadow : true;
});
const currentBackground = computed(() => { return darkMode.value ? `/images/backgrounds/${currentOptions.value[selectedOption.value].value}.jpg` : optionSets[currentSetIndex.value].backgroundPath });
const menuRef = ref(null);
const selectedColor = ref({ color: '#ff6a00', lightColor: '#ff9500', gradient: 'linear-gradient(to right, #ff6a00, #ff9500)' });
// const currentKey = computed(() => { return String(route.query.role) + String(route.query.job)})

const updateColor = (newColor) => {
  selectedColor.value = newColor;
};

onMounted(async () => {
  window.addEventListener('resize', adjustDropdownWidth);
  setMode();
  await setTimeout(async () => {
    const uriData = route.query.data;
    if (uriData) {
      try {
        const decodedData = decodeURIComponent(uriData);
        selectedOption.value = route.query.job ? Number(route.query.job) : 0;
        currentSetIndex.value = route.query.role ? Number(route.query.role) : 0;
        // await adjustDropdownWidth();
        await loadData();
        yuckyMeals.value = stringToJsonArray(decodedData, actionData.value);
      } catch (error) {
        console.error('Error processing URI data:', error);
      }
    }
    else {
      currentSetIndex.value = route.query.role ? Number(route.query.role) : 0;
      selectedOption.value = route.query.job ? Number(route.query.job) : 0;
      await loadData();
    }
    await adjustDropdownWidth();
    changeBackground(currentBackground.value);
    // changeBackground(`url(/images/backgrounds/${selectedOption.value}.jpg)`)
  }, 100);
});

const onSelection = (selected) => {
  selectedOption.value = selected;
  onDropdownChange();
};

async function loadData() {
  try {
    const dataModule = await import(`/data/jobActions_${currentOptions.value[selectedOption.value].value}.json`);
    actionData.value = dataModule.default;
    updateData();
  } catch (error) {
    console.error("Error loading data:", error);
    actionData.value = []; // Reset or handle error
  }
}
async function updateData() {
  gcds.value = actionData.value.filter(item => item.gcd === true && item.roleAction === false);
  ogcds.value = actionData.value.filter(item => item.gcd === false && item.roleAction === false);
  roleActions.value = actionData.value.filter(item => item.roleAction === true);
}

// const adjustDropdownWidth = () => {
//   // setTimeout(() => {
//   //   const text = dropdown.value.options[dropdown.value.selectedIndex].text;
//   //   const canvas = document.createElement('canvas');
//   //   const context = canvas.getContext('2d');
//   //   context.font = getComputedStyle(dropdown.value).font; // Match the font style
//   //   const width = context.measureText(text).width;
//   //   dropdown.value.style.width = `${width}px`; // Add some padding
//   // }, 200);
// };

const adjustDropdownWidth = () => {
  if (dropdown.value) {
    dropdown.value.adjustDropdownWidth();
    dropdown.value.adjustDropdownOptionsWidth();
  }
};

function changeBackground(pathToImage) {
  preloadImage(pathToImage, function () {
    document.getElementById('app').style.backgroundImage = `url(${pathToImage})`;
  });
}

function setMode() {
  const dropdownElements = document.querySelectorAll('.dropdown-selected, .dropdown-optionspan, .dropdown-options');
  const mainWrapper = document.getElementById('mainWrapper');

  dropdownElements.forEach(element => {
    element.classList.toggle('dark-mode', darkMode.value);
    element.classList.toggle('light-mode', !darkMode.value);
  });
  if (!darkMode.value) {
    mainWrapper.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
  } else {
    mainWrapper.style.backgroundColor = 'rgba(0,0,0,0.6)';
  }
}

watch(yuckyMeals, (newValue) => {
  let actionString = jsonArrayToString(newValue, 'actionName');
  if (!isInitialLoad) {
    updateUrl(actionString);
  } else {
    isInitialLoad = false;
  }
}, { deep: true, immediate: true });

function moveToSequence(item) {
  yuckyMeals.value.push(item);
}

function removeFromSequence(index) {
  yuckyMeals.value.splice(index, 1);
}

const updateYuckyMeals = (newMeals) => {
  yuckyMeals.value = newMeals;
};

const handleFFLogsImport = async (payload) => {
  currentSetIndex.value = payload.source[0];
  selectedOption.value = payload.source[1];
  adjustDropdownWidth();
  await loadData();
  yuckyMeals.value = stringToJsonArray(payload.actionString, actionData.value);
  changeBackground(currentBackground.value);
}

function updateUrl(yourData) {
  router.push({ query: { ...route.query, job: selectedOption.value, data: yourData, role: currentSetIndex.value } });
}

function toggleMenu() {
  if (menuRef.value) {
    menuRef.value.isOpen = !menuRef.value.isOpen;
  }
}

function togglePresets() {
  if (presetRef.value) {
    presetRef.value.isOpen = !presetRef.value.isOpen;
  }
}


const onDropdownChange = async () => {
  await loadData();
  updateUrl();
  adjustDropdownWidth();
  yuckyMeals.value.splice(0, sequenceLength.value);
  changeBackground(currentBackground.value);
};


const selectSet = (index) => {
  currentSetIndex.value = index;
  selectedOption.value = 0;
};

function jsonArrayToString(jsonArray, propertyName) {
  return jsonArray.map(item => item[propertyName]).join(',');
}

function stringToJsonArray(idsString, fullDataSet) {
  let ids = idsString.split(',');
  return ids.map(id => fullDataSet.find(item => item.actionName.toUpperCase() == id.toUpperCase())).filter(item => item !== undefined);
}


function preloadImage(url, callback) {
  var img = new Image();
  img.onload = function () {
    callback(); // Call the callback function when the image is loaded
  };
  img.src = url;
}

const updateUserPresets = (newPresets) => {
  userPresets.value = newPresets;
};

async function updateFromURI(data) {
  if (data) {
    await updateUrl(data)
    const cleanData = data = data.replace(/\+/g, ' ');
    if (cleanData) {
      try {
        const decodedData = decodeURIComponent(cleanData);

        // await adjustDropdownWidth();
        yuckyMeals.value = stringToJsonArray(decodedData, actionData.value);
      } catch (error) {
        console.error('Error processing URI data:', error);
      }
    }
  }
}



</script>
<template>
  <div class="center-container">
    <div class="center-content">
      <link rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
      <settingsMenu @fflogs-import="handleFFLogsImport" :yuckyMeals="yuckyMeals" @update-color="updateColor" ref="menuRef"
        @toggle-darkmode="setMode(); changeBackground(currentBackground)" />
      <presetsMenu @user-presets-updated="updateUserPresets" :actionData="actionData" @uri-updated="updateFromURI"
        ref="presetRef" />
      <!-- <h1 style="color:red; background-color: black;">Work in progress</h1> -->
      <div id="mainWrapper">
        <span id="menuSymbol" @click="toggleMenu" class="material-symbols-outlined">settings</span>
        <span id="presetSymbol" @click="togglePresets" class="material-symbols-outlined">save</span>
        <div id="title-container">
          <!-- <select id="title-dropdown-class" class="title-dropdown" v-model="selectedOption" ref="dropdown" @change="onDropdownChange">
        <option v-for="option in currentOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </select> -->
          <custom-dropdown :darkMode="darkMode" @click="setMode();" :options="currentOptions"
            :selectedOption="selectedOption" @selection="onSelection" ref="dropdown" />
          <h1 id="title">Action Sequencer</h1>
        </div>
        <sequenceTimeline :shadow="iconShadow" :yuckyMeals="yuckyMeals" @remove-meal="removeFromSequence"
          @update-meals="updateYuckyMeals" @actionMouseEnter="(item) => tooltipObject = item"
          @actionMouseLeave="tooltipObject = {}" />
        <div class="block-container" style="min-height:50px;">
          <div class="block upper-block blockSpacer"></div>
          <div class="block upper-block">
            <tooltipFrame class="toolTipFrame" :meal="tooltipObject" />
          </div>
          <div class="block upper-block">
            <quickPresets :userPresets="userPresets" @preset-selected="updateFromURI" />
          </div>
        </div>
        <div class="block-container">
          <gcdsComponent :gcds="gcds" :block-title="'gcds'" @add-to-sequence="moveToSequence"
            @actionMouseEnter="(item) => tooltipObject = item" @actionMouseLeave="tooltipObject = {}" />
          <gcdsComponent :gcds="ogcds" :block-title="'ogcds'" @add-to-sequence="moveToSequence"
            @actionMouseEnter="(item) => tooltipObject = item" @actionMouseLeave="tooltipObject = {}" />
          <gcdsComponent :gcds="roleActions" :block-title="'role'" @add-to-sequence="moveToSequence"
            @actionMouseEnter="(item) => tooltipObject = item" @actionMouseLeave="tooltipObject = {}" />
        </div>
        <div class="block-container">
          <div class="block blockSpacer">
          </div>
          <div class="block" id="roleToggleIconWrapper">
            <img :src="set.iconPath" v-for="(set, index) in optionSets" :key="index" class="roleToggleIcon"
              :class="{ roleToggleIconActive: currentSetIndex === index }"
              @click="selectSet(index); onDropdownChange(); setMode();" />
          </div>
          <div class="block blockSpacer"></div>
        </div>
        <!-- <div class="buttonContainer">
          <button v-for="(set, index) in optionSets" :key="index" class ="roleToggleBtn" :class="{ active: currentSetIndex === index }" @click="selectSet(index); onDropdownChange();">
            {{ set.name }}
          </button>
        </div> -->
        <span id="alternateTrashCan" v-on:click="yuckyMeals.splice(0, sequenceLength);">clear</span>
      </div>
    </div>
  </div>
</template>

<style>
html {
  font-size: 1.5rem;
}

ul {
  margin-top: 0;
}

#menuSymbol {
  position: absolute;
  top: 5px;
  right: 5px;
  margin-bottom: 0;
  font-size: 30px;
  color: v-bind('selectedColor.color');
  cursor: pointer;
}

#trashCan:hover,
#menuSymbol:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease-in-out;
}

#presetSymbol {
  position: absolute;
  top: 5px;
  left: 5px;
  margin-bottom: 0;
  font-size: 30px;
  color: v-bind('selectedColor.color');
  cursor: pointer;
}



.block {
  flex: 1;
  /* Distribute available space equally among columns */
  padding: 10px;
  /* Add some spacing between columns */
  border-radius: 30px;
  /* background-color: #393e46a4; */
  margin-top: 0;
  width: 33%;
  box-sizing: border-box;


}

.block-container {
  display: flex;
  /* Use Flexbox */
  justify-content: space-between;
  /* Distribute space between columns */
  width: 100%;
  /*center the container*/
  margin: auto;
}

.defaultPreset::before {
  content: "• ";
  color: v-bind('selectedColor.color');
}

.defaultPreset:hover {
  color: v-bind('selectedColor.color');
}

.userPreset:hover {
  color: v-bind('selectedColor.color');
}

.userPreset::before {
  content: "• ";
  color: v-bind('selectedColor.color');

}

.activePreset {
  color: v-bind('selectedColor.lightColor');
  border-radius: 5px;
}








.keyColorDark {
  color: v-bind('selectedColor.color');
}

.keyColorLight {
  color: v-bind('selectedColor.lightColor');
}

.keyColorGradient {
  color: v-bind('selectedColor.gradient');
}

.keyBackgroundDark {
  background-color: v-bind('selectedColor.color');
}

.keyBackgroundLight {
  background-color: v-bind('selectedColor.lightColor');
}

.keyBackgroundGradient {
  background-color: v-bind('selectedColor.gradient');
}

.quickPreset:hover {
  background-color: v-bind('selectedColor.color');
}

#title-container {
  display: flex;
  align-items: center;
  font-size: 2rem;
  /* Adjust to match the size of your H1 */
  justify-content: center;
  position: relative;
}


#title {
  background: -webkit-linear-gradientv-bind('selectedColor.gradient');
  background: v-bind('selectedColor.gradient');
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
  margin: 0;
  z-index: 1000;
}

#sequenceWrapper {
  background: v-bind('selectedColor.gradient');
}

#sequenceWrapperWrapper::after {
  background: v-bind('selectedColor.lightColor')
    /* Your background color */
  ;
}


.roleToggleIcon {
  max-width: 32px;
  min-width: 32px;
  margin: 5px;
  opacity: 50%;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 50%;
  transition: max-width 0.5s ease-in-out;
  transition: max-width 0.5s ease-in-out;

}

.roleToggleIcon:hover {
  transform: translateY(-5px);
  transition: transform 0.2s ease-in-out;
}

.roleToggleIconActive {
  opacity: 100%;
}

#roleToggleIconWrapper {
  justify-content: center;
  align-items: center;
  width: 33%
}

#trashCan {
  cursor: pointer;
}

.sortable-chosen.userPresetWrapper {
  background-color: v-bind('selectedColor.color');
}

.title-dropdown:focus {
  outline: none;
}

.dropdown-selected.dark-mode {
  color: rgb(255, 255, 255);
  /* Dark mode color */
}

.dropdown-selected.light-mode {
  color: black;
  /* Light mode color */

}

.dropdown-selected:hover {
  color: v-bind('selectedColor.color');
  transition: all .2s ease-in;
  border: none;
  outline: none;
}

.dropdown-optionspan:hover {
  color: v-bind('selectedColor.color');
  transition: all .2s ease-in;
  border: none;
  outline: none;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  border: none;
}

.functionalButton {
  background-color: v-bind('selectedColor.color');
  color: white;
}



#menuButton {
  margin-top: 10px;
  background-color: v-bind('selectedColor.color');
}

#alternateTrashCan {
  display: none;
}

#actionName {
  color: v-bind('selectedColor.color');

}

.block.upper-block {
  display: flex;
  justify-content: center;
}


.active {
  background-color: v-bind('selectedColor.color');
  color: white;
  box-shadow: inset 0 0 10px #00000067;
}

.presetToolbarItem {
  background-color: v-bind('selectedColor.color');
}

.pageButton.activePageButton {
  background-color: v-bind('selectedColor.color');
}


#mainWrapper {
  /* background-color:rgba(0,0,0,0.7); */
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(0px);
  padding: 30px;
  position: relative;
}

.context-menu div:hover {
  background-color: v-bind('selectedColor.lightColor');
}

.context-menu {
  background: v-bind('selectedColor.color');
}


@media(min-width:1200px) {

  .title-dropdown {
    font-size: 2.5rem;
  }

  .dropdown {
    font-size: 2.5rem;
  }

  .roleToggleIcon {
    max-width: 48px;
    min-width: 32px;
  }
}

@media (max-width: 1000px) {

  .roleToggleBtn {
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 1%;
    width: 10%;
    text-align: center;
    font-size: 3vw;
    overflow: hidden;
    white-space: nowrap;
    border: none;
    outline: none;

  }

  .block {
    margin: 1%;
    margin-top: 0;
    width: 100%;
    box-sizing: border-box;
  }

  #roleToggleIconWrapper {
    justify-content: center;
    align-items: center;
    width: 100%
  }



  .block-container {
    display: block;

  }

  #sequenceWrapper {
    min-height: 50px;
  }

  .block.upper-block {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .block.blockSpacer {
    display: none;
  }

}


@media(max-width:750px) {

  #trashCan,
  #sequenceWrapperWrapper::after {
    display: none;
  }

  #alternateTrashCan {
    display: block;
    cursor: pointer;
    color: v-bind('selectedColor.color');
  }
}
</style>

