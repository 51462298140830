<template>
      <div id="appContainer">
        <router-view />
      </div>
</template>

<script setup>
</script>


<style>
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 100%;
}

body {
  background: #C9CCD3;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  font-size: 1rem;
}




#app {
  font-family: 'Bebas Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 0.5s ease-in-out;
  background-size: cover;
  min-height: 100vh;
  min-width: 100%;
}

#appContainer {
  position: relative;
  min-width: 100%;
  min-height: 100vh;

}



.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.center-content {
  width: 100%;
  text-align: center;
}
</style>
