import { createApp } from 'vue'
import App from './App.vue'
// import '@coreui/coreui/dist/css/coreui.min.css'
import './cssSheets/default.css'
import { createRouter, createWebHistory } from 'vue-router';
import DragMe2 from './components/DragMe2.vue'
import Vue3TouchEvents from "vue3-touch-events";
import { createHead } from '@unhead/vue'







const router = createRouter({
    history: createWebHistory(),
    routes: [
      {path: '/', component: DragMe2},
    ],
  });
const head = createHead()
const app = createApp(App);
app.use(head)
app.use(Vue3TouchEvents);
app.use(router);
app.mount('#app')
