<template>
  <div class="dropdown" ref="dropdownElement">
    <div class="dropdown-selected" @click="toggleDropdown">
      <span id="jobdropdown-caret" class="material-symbols-outlined keyColorDark">expand_more</span>{{
        options[selectedOption].label }}
    </div>
    <transition name="slide-fade">
      <div class="dropdown-options" ref="dropdownOptionsElement" v-show="isOpen">
        <div v-for="(option, index) in options" :key="option.value" @click="selectOption(index)">
          <span class="dropdown-optionspan" :class="[props.darkMode === true ? 'dark-mode' : 'light-mode']"
            v-show="index !== selectedOption">{{ option.label }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>
  
<script setup>
import { ref, defineProps, defineEmits, watch, nextTick, onMounted, defineExpose } from 'vue';

const props = defineProps({
  options: Array,
  selectedOption: Number,
  darkMode: Boolean,
});

const dropdownElement = ref(null);
const dropdownOptionsElement = ref(null);
const emit = defineEmits(['selection', 'toggle-darkmode']);

const isOpen = ref(false);
const selectedOption = ref(props.selectedOption);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    adjustDropdownOptionsWidth();
  }
};


const selectOption = (option) => {
  selectedOption.value = option;
  emit('selection', option);
  isOpen.value = false;
};


const adjustDropdownWidth = () => {
  nextTick(() => {
    if (dropdownElement.value) {
      setTimeout(() => {
        const text = props.options[selectedOption.value]?.label || '';
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = getComputedStyle(dropdownElement.value).font; // Match the font style
        const width = context.measureText(text).width;
        dropdownElement.value.style.width = `${width + 24}px`; // Add some padding
      }, 200);
    }
  });
};

const adjustDropdownOptionsWidth = () => {
  nextTick(() => {
    if (dropdownOptionsElement.value) {
      let maxWidth = 0;
      dropdownOptionsElement.value.querySelectorAll('.dropdown-optionspan').forEach(el => {
        maxWidth = Math.max(maxWidth, el.offsetWidth);
      });
      dropdownOptionsElement.value.style.width = `${maxWidth + 48}px`;
    }
  });
};

defineExpose({ adjustDropdownWidth, adjustDropdownOptionsWidth });

watch(selectedOption, adjustDropdownWidth, adjustDropdownOptionsWidth, { immediate: true });
onMounted(adjustDropdownWidth);

watch(() => props.selectedOption, (newValue) => {
  selectedOption.value = newValue;
}, { immediate: true });
</script>
  
<style scoped>
.dropdown {
  position: relative;
  border: none;
  font-size: calc(1.375rem + 1.5vw);
  -webkit-appearance: none;
  /* Removes default styling on Safari */
  -moz-appearance: none;
  /* Removes default styling on Firefox */
  -moz-transition: all .1s ease-in;
  -o-transition: all .1s ease-in;
  -webkit-transition: all .1s ease-in;
  transition: all .1s ease-in;
  appearance: none;
  margin-right: 0.5rem;
  overflow: visible;
  padding: 0;
  color: black;
  white-space: nowrap;
  cursor: pointer;

}

.dropdown-options {

  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: transparent;
  z-index: 1;
  text-align: left;
  max-height: 1000px;
  opacity: 1;
  border-radius: 0px 0px 10px 10px;

}

.dropdown-options.dark-mode {
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), 75%, rgba(0, 0, 0, 1));
}

.dropdown-options.light-mode {
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), 75%, rgba(255, 255, 255, 1));
}

.dropdown-optionspan.dark-mode {
  color: rgb(255, 255, 255);
}

.dropdown-optionspan.light-mode {
  color: black;
}


.dropdown .dropdown-options>div {
  padding-left: 24px;
}


#jobdropdown-caret {
  min-width: 24px;
}

/* Initial state when the element is entering */
.slide-fade-enter-from {
  transform: translateY(-10px);
  /* Start above the actual position */
  opacity: 0;
}

/* Active state during the entering and leaving transitions */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

/* Final state for the entered element */
.slide-fade-enter-to {
  transform: translateY(0);
  /* Slide to actual position */
  opacity: 1;
}

/* Starting state when the element is leaving */
.slide-fade-leave-from {
  transform: translateY(0);
  opacity: 1;
}

/* Final state when the element has left */
.slide-fade-leave-to {
  transform: translateY(-30px);
  /* Slide back up */
  opacity: 0;
}


@media(min-width:1200px) {
  .dropdown {
    font-size: 2.5rem;
  }
}</style>
  