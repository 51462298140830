<template>
    <div v-if="checkEmpty" id="quickPresetContainer">
        <div class="quickPreset keyBackgroundLight" :key="preset.id" @click="presetSelected(preset)"
            v-for="preset in quickPresets">{{ preset.name }}</div>
    </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, watch, ref } from 'vue';

import { useRoute, } from 'vue-router';

const route = useRoute();
const currentUri = computed(() => { return route.query.data })
const quickPresets = ref([])
const checkEmpty = computed(() => {
    if (quickPresets.value.length > 0) {
        return true;
    }
    else {
        return false;
    }
})

const props = defineProps({
    userPresets: Object
});



const emit = defineEmits(['preset-selected']);

const presetSelected = (preset) => {
    if (!currentUri.value) {
        return emit('preset-selected', preset.uri);
    }
    else {
        const uri = currentUri.value + '%2C' + preset.uri
        emit('preset-selected', uri);
    }
}




watch(() => props.userPresets, (newValue) => {
    quickPresets.value = Object.values(newValue).filter(preset => preset.quickPreset === true)
}, { deep: true, });


</script>

<style scoped>
#quickPresetContainer {
    width: 100%;
    display: flex;
    line-height: 1;
    margin: 0;
    padding: 0;
    overflow: auto;
    justify-content: center;
    ;
    padding-left: 15px;
    padding-right: 15px;
}

.quickPreset {
    font-size: .7rem;
    padding: 5px;

    line-height: 1;
    align-items: center;
    margin-right: 5px;
    margin-left: 5px;
    width: 20%;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;

}

@media(max-width:1000px) {

    #quickPresetContainer {
        justify-content: center;
        align-items: center;
        width: 100%
    }

}
</style>
