<template>
    <div>
        <span class="material-symbols-outlined" style="font-size:2rem;">file_open</span>
    </div>
    <div>
        <span>FFLogs Import</span> <br>
        <span style="color:red;" class="import-error-string">{{ errorString }}</span>
    </div>
    <div v-if="isImporting == false">
        <input id="fflogsImportInput" v-model="importUrl" placeholder="Paste FFLogs URL here" autocomplete="off" />
        <button id="fflogsImport" class="functionalButton" @click="handleImport">Import</button>
    </div>
    <div v-if="isImporting">
        <n-spin size="medium" />
    </div>
</template>
  
<script setup>

import { ref, defineEmits } from 'vue';
import { NSpin} from 'naive-ui';

const importUrl = ref('');
const errorString = ref('');
const isImporting = ref(false);
import { optionSets } from '/data/optionSets.js';

const emit = defineEmits(['fflogs-import', 'close-popup']);
import axios from 'axios';

//FIXME: Update to prefer ID over name
const handleImport = async () => {
    if (importUrl.value) {
        const payload = extractUrlInfo(importUrl.value);
        if (!payload) return;
        try {
            isImporting.value = true;
            const response = await axios.post('/api/sequencer', payload);
            let eventArray = response.data.eventResponse.events;
            let castArray = eventArray.filter(event => event.type === 'cast');
            //find any casts that have ability.name Medicated and change them to potion
            castArray.forEach(event => {
                if (event.ability.name == 'Medicated') {
                    event.ability.name = 'Potion';
                }
            });
            if (response.data.source.type == 'Dancer') {
                let previousEvent = null;
                castArray.forEach(event => {
                    let abilityName = event.ability.name.toLowerCase();
                    if (abilityName.includes('technical finish')) {
                        event.ability.name = 'technical finish';
                        if(previousEvent && previousEvent.ability.name == 'technical finish'){
                            event.ability.name = 'bigchungus';
                        }
                    }
                    if (abilityName.includes('standard finish')) {
                        event.ability.name = 'standard finish';
                    }
                    previousEvent = event;
                });
            }
            let actionString = castArray.map(event => event.ability.name).join(',');
            emit('fflogs-import', { 'actionString': actionString, 'source': findIndexes(response.data.source.type.toLowerCase()) });
            isImporting.value = false;
        } catch (error) {
            if (error.response && error.response.status === 429) {
                errorString.value = 'Too many requests, try again in a minute';
                isImporting.value = false;
                return
            } else {
                isImporting.value = false;
                return
            }
        }
        importUrl.value = '';
        emit('close-popup');
    }
}

function findIndexes(valueQuery) {
    for (let i = 0; i < optionSets.length; i++) {
        const options = optionSets[i].options;
        for (let j = 0; j < options.length; j++) {
            if (options[j].value == valueQuery) {
                return [i, j]; // Returns parent index and child index
            }
        }
    }
    return [-1, -1]; // Returns -1, -1 if not found
}


function extractUrlInfo(url) {
    // Validate the full URL
    if (!url || typeof url !== 'string') {
        errorString.value = 'Invalid URL';
        return
    }
    let urlObj;

    try {
        urlObj = new URL(url);
    } catch (e) {
        errorString.value = 'Bad URL, see guide for proper format';
        return
    }

    if (urlObj.hostname !== 'www.fflogs.com') {
        errorString.value = 'Must be an FFLogs url!';
        return
    }

    // Extract and validate components
    const pathSegments = urlObj.pathname.split('/').filter(Boolean);
    if (pathSegments[0] !== 'reports' || !pathSegments[1]) {
        errorString.value = 'URL does not contain a valid report ID.';
        return;
    }
    const reportID = pathSegments[1];

    // Validate hash parameters
    const hash = urlObj.hash.substring(1);
    const hashParams = new URLSearchParams(hash);
    const source = hashParams.get('source');
    const start = hashParams.get('start');
    const end = hashParams.get('end');

    if (!source || !start || !end) {
        errorString.value = 'URL Missing required parameter (source, start, end).';
        return
    }
    return { reportID, source, start, end };
}


</script>

<style>
#fflogsImport {
    margin-top: 10px;
}

.functionalButton {
    width: 46%;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: none;
    border-radius: 5px;
    margin-right: 1%;
    margin-left: 1%;
}

#fflogsImportInput {
    width: 95%;
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: none;
    padding: 5px;
    text-align: center;
}
</style>