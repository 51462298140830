
<template>
  <div id="sequenceWrapperWrapper">
    <draggable :disabled="true" v-model="previewMeals" tag="div" :group="{ name: 'preview' }" :animation="300" id="sequenceWrapper"
      item-key="id">
      <template #item="{ element: meal, }">
        <div :class="{'sequence-preview-icon-frame': meal.gcd === true,}">
        <img :src="meal.iconPath" :alt="meal.actionName"
          :class="{ 'gcd': meal.gcd === true, 'ogcd': meal.gcd === false }" />
        </div>
      </template>
    </draggable>
  </div>
</template>

<script setup>
/* eslint-disable */
import draggable from 'vuedraggable';
import { ref, watch } from 'vue';
import { defineProps, defineEmits } from 'vue';

const previewMeals = ref([]);


const props = defineProps({
  selectedPreset: Object,
  actionData: Array
});

async function updateFromURI(data) {
  if (data.uri) {
    const cleanData = data.uri.replace(/\+/g, ' ');
    if (cleanData) {
      try {
        const decodedData = decodeURIComponent(cleanData);

        // await adjustDropdownWidth();
        previewMeals.value = stringToJsonArray(decodedData, props.actionData);
      } catch (error) {
        console.error('Error processing URI data:', error);
      }
    }
  }
}

watch(() => props.selectedPreset, (newValue) => {
  if (newValue && newValue.uri) {
    updateFromURI(newValue)
  }
  else {
    previewMeals.value = [];
  }
}, { deep: true, immediate: true });

function stringToJsonArray(idsString, fullDataSet) {
  let ids = idsString.split(',');
  return ids.map(id => fullDataSet.find(item => item.actionName === id));
}





function removeMeal(index) {
  emit('remove-meal', index);
}

function actionMouseEnter(item) {
  emit('actionMouseEnter', item);
}

function actionMouseLeave(item) {
  emit('actionMouseLeave', item);
}

</script>
  
<style scoped>
#sequenceWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 10px;
  width: 90%;
  height: 30%;
  /* border: 5px solid rgb(117, 58, 255); */
  border-radius: 10px;
  padding: 5px;
  border-radius: 30px;
  min-height: 70px;
  overflow-y: auto;
  max-height: 150px;

}

#sequenceWrapper::-webkit-scrollbar {
  display: none;
}

#sequenceWrapperWrapper {
  position: relative;
}



.ogcd {
  max-width: 27px;
  margin: 0;
  border-radius: 50%;
}

.gcd {
height:100%;
width:100%
}

.sequence-preview-icon-frame{
  max-width: 40px;
  margin: 5px;
  position:relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.sequence-preview-icon-frame::after{
  content: " ";
  display: block;
  position:absolute;
  top:0;
  bottom:0;
  background-image:url(/public/images/icons/bevelframe.png);
  background-size:contain;
  width:100%;
  height:auto;
  pointer-events:none;
  box-shadow: 0 0 10px #000000;
  border-radius:5px;
}


@media(max-width:480px) {



  .sequence-preview-icon-frame{
  max-width: 32px;
  max-height:32px;

}

  .ogcd {
    max-width: 20px;
    max-height: 20px;
  }

}

/* Tablet styles */
@media(min-width:481px) and (max-width:800px) {


.sequence-preview-icon-frame{
  max-width: 32px;
  max-height:32px;

}

  .ogcd {
    max-width: 20px;
    max-height: 20px;
  }

  #sequenceWrapper {
    min-height: 50px;
  }

}</style>
  