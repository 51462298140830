<template>
  <div class="block">
    <h1 class="blockHeader" @click="toggleContent">{{ blockTitle }}</h1>
    <draggable class="iconContainer" v-model="localGcds" tag="div" :group="{ name: 'meals', pull: 'clone', put: false }" :sort="false"
      :animation="300" item-key="id">
      <template #item="{ element: meal }">
        <div class="actionIconFrame">
        <img class="actionIcon" :src="meal.iconPath" :alt="meal.actionName" @dblclick="emitAddToSequence(meal)"
          @mouseenter="actionMouseEnter(meal)" @mouseleave="actionMouseLeave(meal)" />
        </div>
      </template>
    </draggable>
  </div>
</template>

  
<script setup>
import { ref, watch } from 'vue';
import draggable from 'vuedraggable';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  gcds: Array,
  blockTitle: String
});

const emit = defineEmits(['add-to-sequence', 'actionMouseEnter', 'actionMouseLeave']);

const localGcds = ref([]);

watch(() => props.gcds, (newGcds) => {
  localGcds.value = newGcds;
}, { immediate: true });

function emitAddToSequence(item) {
  emit('add-to-sequence', item);
}

function actionMouseEnter(item) {
  emit('actionMouseEnter', item);
}

function actionMouseLeave(item) {
  emit('actionMouseLeave', item);
}


</script>
  
<style scoped>
/* Styles specific to GCDs */
.block {
  flex:auto;
  /* Distribute available space equally among columns */
  padding: 10px;
  /* Add some spacing between columns */
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  /* background-color: #393e46a4; */
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 0;
  width: 33%;

}

.blockHeader {
  text-align: center;
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'Bebas Neue';
}

.actionIcon {

width:100%;
height: auto;
user-select:none;
}

.actionIcon:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
  /* box shadow glow effect */
  box-shadow: 0 0 10px #000000;

}

.actionIconFrame{
  margin:5px;
  transition: all 0.2s ease-in-out;
  max-width: 64px;
  min-width: 48px;
  width:15%;
  position:relative;
}

.actionIconFrame::after{
  content: " ";
  display: block;
  position:absolute;
  top:0;
  bottom:0;
  background-image:url(/public/images/icons/bevelframe.png);
  background-size:contain;
  width:100%;
  height:auto;
  pointer-events:none;
  box-shadow: 0 0 10px #000000;
  border-radius:5px;
}

.actionIconFrame:hover::after{
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
  /* box shadow glow effect */
}

.iconContainer{

  display:flex;
  flex-wrap:wrap;
  justify-content: center;
}

/* Mobile styles */

@media(max-width:500px) {


  .actionIconFrame{
    margin: 1%;
    width:auto;
    aspect-ratio: 1 / 1;
    max-width: 36px;
    min-width: 10px;
    max-height:36px;
    min-height:10px;
    
  }
  .actionIcon{
    width:100%;
    height: 100%;
    user-select:none;
  }


  .blockHeader {
    font-size: 1rem;
  }
}

.block {
  margin: 1%;
  margin-top: 0;
  width: 100%
}

/* Tablet styles */
@media(min-width:501px) and (max-width:1000px) {
  .blockHeader {
    font-size: 1.5rem;
  }



  .actionIconFrame{
    width: 10%;
    max-width: 48px;
    min-width: 32px;
  }
}</style>
  