
<template>
  <transition name="slide">
    <div class="slideMenu" v-if="isOpen">
      <div class="cogSpacer">
        <span id="slideMenuGearCog" class="material-symbols-outlined">save</span>
      </div>
      <h4>preview</h4>
      <sequencePresetPreview :selected-preset="selectedPreset" :actionData="props.actionData" />
      <div v-if="windowWidth > 500" id="options-inner-container">
        <div id="leftPresetColumn">
          <div class="presetHeader">
            <h4>{{ `Default presets` }}</h4>
          </div>
          <div class="settingsLine"></div>
          <span class="defaultPreset" @click="setSelectedPreset(preset)" v-for="preset in activeDefaultPresets"
            :class="{ activePreset: isSelectedPreset(preset) }" :key="preset.name"> {{ preset.name }}<br> </span>
        </div>
        <div id="rightPresetColumn">
          <h4>User Presets</h4>
          <div class="settingsLine"></div>
          <draggable id="userPresetContainer" :disabled="!isDraggable" v-model="userPresets" tag="div"
            :group="{ name: 'userPresets' }" :sort="true" :animation="300" item-key="id" @Start="isInputDisabled = true">
            <template #item="{ element: preset, index }">
              <div class="userPresetWrapper">
                <input maxlength="14" v-if="selectedPresetIndex == index && !isInputDisabled" @keyup.enter="toggleInput"
                  :disabled="isInputDisabled" type="text" v-model="preset.name"
                  :class="{ activePreset: isSelectedPreset(preset), hiddenPreset: !isActiveOnPage(index) }"
                  class="userPresetInput" :id="`userPresetInput${index}`">
                <span @touchstart="handleTouchStart($event, index, preset)" @touchend="handleTouchEnd($event, index)"
                  @contextmenu.prevent=" showContextMenu($event, index);"
                  @click="setSelectedPreset(preset, index); isInputDisabled = true;"
                  :class="{ activePreset: isSelectedPreset(preset), hiddenPreset: !isActiveOnPage(index) }"
                  class="userPreset" v-else :id="`userPresetSpan${index}`">{{ preset.name }} </span>
              </div>
            </template>
          </draggable>
          <div id="pageButtonsContainer">
            <button v-for="n in totalPages" class="pageButton" :class="{ activePageButton: currentPage === n }"
              @click="currentPage = n" :key="n" @dragover.prevent="handleDragOverPageButton(n)">{{ n }} </button>
          </div>
          <div class="presetToolBar">
            <span @click="saveUserPreset(); goToNewPreset();"
              class="material-symbols-outlined presetToolbarItem">save</span>
            <span v-if="!isDraggable" @click="toggleDraggable"
              class="material-symbols-outlined presetToolbarItem">lock</span>
            <span v-else @click="toggleDraggable"
              class="material-symbols-outlined presetToolbarItem">lock_open_right</span>
          </div>

        </div>
      </div>
      <div v-if="windowWidth < 500" id="options-inner-container">
        <div v-if="activeTab === 'left'" id="leftPresetColumn">
          <div class="presetHeader">
            <h4>{{ `Default presets` }}</h4>
            <span class="material-symbols-outlined right" @click="activeTab = 'right'">chevron_right</span>
          </div>
          <div class="settingsLine"></div>
          <span class="defaultPreset" @click="setSelectedPreset(preset)" v-for="preset in activeDefaultPresets"
            :class="{ activePreset: isSelectedPreset(preset) }" :key="preset.name"> {{ preset.name }}<br> </span>
        </div>
        <div v-if="activeTab === 'right'" id="rightPresetColumn">
          <div class="presetHeader">
            <span class="material-symbols-outlined left" @click="activeTab = 'left'">chevron_left</span>
            <h4>User Presets</h4>
          </div>
          <div class="settingsLine"></div>
          <draggable id="userPresetContainer" :disabled="!isDraggable" v-model="userPresets" tag="div"
            :group="{ name: 'userPresets' }" :sort="true" :animation="300" item-key="id" @Start="isInputDisabled = true">
            <template #item="{ element: preset, index }">
              <div class="userPresetWrapper">
                <input maxlength="14" v-if="selectedPresetIndex == index && !isInputDisabled" @keyup.enter="toggleInput"
                  :disabled="isInputDisabled" type="text" v-model="preset.name"
                  :class="{ activePreset: isSelectedPreset(preset), hiddenPreset: !isActiveOnPage(index) }"
                  class="userPresetInput" :id="`userPresetInput${index}`">
                <span @touchstart="handleTouchStart($event, index, preset)" @touchend="handleTouchEnd($event, index)"
                  @contextmenu.prevent=" showContextMenu($event, index);"
                  @click="setSelectedPreset(preset, index); isInputDisabled = true;"
                  :class="{ activePreset: isSelectedPreset(preset), hiddenPreset: !isActiveOnPage(index) }"
                  class="userPreset" v-else :id="`userPresetSpan${index}`">{{ preset.name }} </span>
              </div>
            </template>
          </draggable>
          <div id="pageButtonsContainer">
            <button v-for="n in totalPages" class="pageButton" :class="{ activePageButton: currentPage === n }"
              @click="currentPage = n" :key="n" @dragover.prevent="handleDragOverPageButton(n)">{{ n }} </button>
          </div>
          <div class="presetToolBar">
            <span @click="saveUserPreset(); goToNewPreset();"
              class="material-symbols-outlined presetToolbarItem">save</span>
            <span v-if="!isDraggable" @click="toggleDraggable"
              class="material-symbols-outlined presetToolbarItem">lock</span>
            <span v-else @click="toggleDraggable"
              class="material-symbols-outlined presetToolbarItem">lock_open_right</span>
          </div>

        </div>
      </div>
      <button id="addToSequenceButton" class="functionalButton" @click="addToUri(); setSelectedPreset({}, null)">Add To
        Sequencer</button>
      <div class="cogSpacer">
        <span id="closeMenuButton" @click="isOpen = false" class="material-symbols-outlined">chevron_left</span>
      </div>
    </div>
  </transition>
  <div class="overlay" @click="closeContextMenu" v-if="showMenu" />
  <contextMenu v-if="showMenu" :actions="contextMenuActions" @action-clicked="handleActionClick" :x="menuX" :y="menuY" />
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script setup>
/* eslint-disable */
import { ref, defineExpose, computed, onMounted, watch, defineEmits, defineProps, onUnmounted } from 'vue';
import draggable from 'vuedraggable';
import { useRoute, } from 'vue-router';
import sequencePresetPreview from './sequencePresetPreview.vue';


const props = defineProps({
  actionData: Array
});     // Define props for the component         

const setSelectedPreset = (preset, index) => {
  selectedPreset.value = preset;
  if (index !== null) {
    selectedPresetIndex.value = index;
  }
};

const goToNewPreset = () => {
  currentPage.value = totalPages.value
}
const isSelectedPreset = (preset) => {
  return selectedPreset.value && (preset.uri === selectedPreset.value.uri);
};

const deleteUserPreset = (index) => {
  userPresets.value.splice(index, 1);
  selectedPreset.value = {};
  selectedPresetIndex.value = null;
};

const toggleInput = () => {
  if (selectedPresetIndex.value !== null) {
    isInputDisabled.value = !isInputDisabled.value;
  }
};

const onResize = () => {
  windowWidth.value = window.innerWidth;
};


function isActiveOnPage(index) {
  return (index >= (currentPage.value - 1) * 8) && (index < currentPage.value * 8);
}

function saveOverPreset() {
  if (selectedPreset.value.type === 'user') {
    let currentPreset = selectedPreset.value;
    userPresets.value[selectedPresetIndex.value] = {
      name: currentPreset.name,
      active: false,
      uri: route.query.data,
      type: 'user'
    };
    selectedPreset.value = userPresets.value[selectedPresetIndex.value]
  }
}


onMounted(async () => {
  try {
    window.addEventListener('resize', onResize);
    const loadedPresets = await import('/data/defaultSequencerPresets.js');
    defaultPresets.value = loadedPresets;
    isDataLoaded.value = true; // Set to true after data is loaded
    setTimeout(() => {
      userPresets.value = loadLocalStorage();
    }, 100);
  } catch (error) {
    console.error("Failed to load presets:", error);
    // Handle the error appropriately
  }

});


onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});
const activeDefaultPresets = computed(() => {
  if (defaultPresets.value.length > 0) {
    return defaultPresets.value[currentSet.value][currentJob.value].presets;
  }
  return [];
});
// const activeJobName = computed(() => {
//     if (defaultPresets.value.length > 0) {
//         return defaultPresets.value[currentSet.value][currentJob.value].label;
//     }
//     return ' ';
// });
const currentPage = ref(1);
const totalPages = computed(() => {
  return Math.ceil(userPresets.value.length / 8);
});
const isOpen = ref(false);
const windowWidth = ref(window.innerWidth);
const isInputDisabled = ref(true);
const isDataLoaded = ref(false);
const isDraggable = ref(false);
const userPresets = ref(null);
const activeTab = ref('left');
const route = useRoute();
const selectedPreset = ref({})
const selectedPresetIndex = ref(null)
const currentJob = computed(() => { return route.query.job ? Number(route.query.job) : 0; })
const currentSet = computed(() => { return route.query.role ? Number(route.query.role) : 0; })
const currentSequence = computed(() => { return route.query.data; })
const currentKey = computed(() => { return String(currentSet.value) + String(currentJob.value) })
const emit = defineEmits(['uri-updated', 'user-presets-updated']);
const touchStartTimer = ref(null);

const handleTouchStart = (event, index, preset) => {
  touchStartTimer.value = setTimeout(() => {
    setSelectedPreset(preset, index);
    showContextMenu(event, index);
  }, 500); // 500 ms for long press
};

const toggleDraggable = () => {
  isDraggable.value = !isDraggable.value;
}

const handleTouchEnd = () => {
  clearTimeout(touchStartTimer.value);
};

const defaultPresets = ref([]);

function updateLocalStorage(newValue) {
  localStorage.setItem(currentKey.value, JSON.stringify(newValue));
  emit('user-presets-updated', newValue);
}


function loadLocalStorage() {
  const loadedPresets = JSON.parse(localStorage.getItem(currentKey.value));
  if (loadedPresets) {
    return loadedPresets;
  }
  return []; // Return an empty array if there's nothing in local storage
}

function saveUserPreset() {
  const preset = {
    name: 'New Preset',
    active: false,
    uri: route.query.data,
    type: 'user'
  }
  userPresets.value.push(preset);
}

function addToUri() {
  if (!selectedPreset.value.uri) { return console.log("No sequence selected") }
  if (!currentSequence.value) {
    return emit('uri-updated', selectedPreset.value.uri);
  }
  else {
    let newURI = currentSequence.value + '%2C' + selectedPreset.value.uri;
    emit('uri-updated', newURI);
  }

}


// watch(() =>userPresets.value, (newValue) => {
//     updateLocalStorage(newValue);
//   }, { deep: true, immediate: true });

watch(() => currentKey.value, () => {
  userPresets.value = loadLocalStorage();
  selectedPreset.value = {};
  selectedPresetIndex.value = null;
}, { deep: true, immediate: true });

watch(() => userPresets.value, (newData) => {
  if (JSON.stringify(newData) !== JSON.stringify(null)) {
    if (isInputDisabled.value == true) {
      updateLocalStorage(newData);
    }
  }
}, { deep: true, immediate: true });

watch(() => isInputDisabled.value, () => {
  if (isInputDisabled.value == true) {
    updateLocalStorage(userPresets.value);
  }
}, { deep: true, });

defineExpose({ isOpen });

import contextMenu from './contextMenu.vue';
const showMenu = ref(false);
const menuX = ref(0);
const menuY = ref(0);
const targetIndex = ref({});
const contextMenuActions = ref([
  { label: 'Rename', action: 'Rename' },
  { label: 'Set Quick-Preset', action: 'quickPreset' },
  { label: 'Overwrite', action: 'overwrite' },
  { label: 'Delete', action: 'delete' },
]);

const handleDragOverPageButton = (pageNumber) => {
  currentPage.value = pageNumber;  // Change the page when dragging over
};

const showContextMenu = (event, index) => {
  if (isDraggable.value) return;
  event.preventDefault();
  targetIndex.value = index;
  if (userPresets.value[targetIndex.value].quickPreset == true) {
    contextMenuActions.value[1].label = 'Remove Quick-Preset';
    contextMenuActions.value[1].action = 'removeQuickPreset';
  }
  else {
    contextMenuActions.value[1].label = 'Set Quick-Preset';
    contextMenuActions.value[1].action = 'quickPreset';
  }
  showMenu.value = true;
  if (event.clientX) {
    menuX.value = event.clientX;
    menuY.value = event.clientY;
  }
  else {
    menuX.value = event.changedTouches[0].clientX;
    menuY.value = event.changedTouches[0].clientY;
  }
};

const closeContextMenu = () => {
  showMenu.value = false;
};

function handleActionClick(action) {
  if (action === 'delete') {
    deleteUserPreset(targetIndex.value);
  }
  else if (action === 'Rename') {
    selectedPreset.value = userPresets.value[targetIndex.value];
    selectedPresetIndex.value = targetIndex.value;
    if (isInputDisabled.value) {
      toggleInput();
    }
  }
  else if (action === 'overwrite') {
    selectedPreset.value = userPresets.value[targetIndex.value];
    selectedPresetIndex.value = targetIndex.value;
    saveOverPreset();
  }
  else if (action === 'quickPreset') {
    userPresets.value[targetIndex.value].quickPreset = true;
  }
  else if (action === 'removeQuickPreset') {
    userPresets.value[targetIndex.value].quickPreset = false;
  }
  closeContextMenu();
}



</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.slideMenu {
  position: fixed;
  top: calc(50% - 359px);
  width: 800px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 500;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 0px 10px 10px 0px;
  user-select: none;

}

.settingsLine {
  width: 45%;
  margin: 0px auto 10px auto;
  height: 1px;
  background-color: white;
}

.presetHeader {
  display: flex;
  /* Use Flexbox */
  justify-content: center;
  /* Center the content horizontally */
  align-items: center;
  /* Align items vertically */
  position: relative;
  /* Positioning context for the chevron */
}

.presetHeader h4 {
  margin: 0;
  /* Remove default margins */
}

.presetHeader .material-symbols-outlined.right {
  position: absolute;
  right: 75px;

}

.presetHeader .material-symbols-outlined.left {
  position: absolute;
  left: 75px;


}


.defaultPreset {
  cursor: pointer;
}

.defaultPreset::before {
  content: "• ";
}

.defaultPreset:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

.userPreset {
  cursor: pointer;
  break-inside: avoid;
  width: 100%;
  z-index: 1000;
  user-select: none;

}

.hiddenPreset {
  display: none;
}


#userPresetContainer {
  column-count: 2;
  width: 75%;
  margin: auto;
  height: 6em;
  /* overflow-y:scroll; */
}




.userPresetWrapper {
  margin: auto;
}

#options-inner-container {
  width: 100%;
  margin: auto;
  display: flex;
}

#leftPresetColumn {
  width: 50%;
}

#rightPresetColumn {
  width: 50%;
}

.functionalButton {
  width: 46%;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: none;
  border-radius: 5px;
  margin-right: 1%;
  margin-left: 1%;
}

#exportContainer {
  width: 45%;
  margin: auto;
}


.functionalButton:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease-in-out;
}

#slideMenuGearCog {
  margin: auto;
  line-height: 2;
  font-size: 2rem;
}

#closeMenuButton {
  margin: auto;
  line-height: 2;
  font-size: 2rem;
  cursor: pointer;
}

.cogSpacer {
  display: block;
  height: 4rem;
  position: relative;
}

.presetToolBar {

  transform: translateY(-2px);
  margin-top: 10px;
  transition: transform 0.2s ease-in-out;

}

.presetToolbarItem {
  cursor: pointer;
  font-size: 30px;
  border-radius: 10%;
  padding: 3px;
  margin: 5px;
  user-select: none;


}

.presetToolbarItem:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease-in-out;
}

.userPresetInput {
  background: transparent;
  border: none;
  color: white;
  text-align: center;
  width: 100%;
  height: 1em;
  border: 1px solid white;
}

#addToSequenceButton {
  height: 50px;
  margin-top: 20px;
}


.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.pageButton {
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  cursor: pointer;
  user-select: none;
  margin-left: 5px;
  margin-right: 5px;

}

.pageButton:hover {
  transform: scale (1.1);
  transition: transform 0.2s ease-in-out;
}

#pageButtonsContainer {
  height: 32px;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
}



.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 999;
}

.overlay::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}

.overlay:hover {
  cursor: pointer;
}



@media(min-width:481px) and (max-width:800px) {
  /* 
    .slideMenu {
    position: fixed;
    top: 0;
    width:400px;
    left:0;
    background-color: rgba(0,0,0,0.7);
    z-index: 500; 
    backdrop-filter: blur(10px);
    justify-content: center;
    align-items: center;
    border-radius: 0px 10px 10px 0px;
  }

  .userPresetWrapper{
    height:20px;
  }
  #options-inner-container{
    width:100%;
    margin:auto;
    display:block;
  }
  #leftPresetColumn{
    width:100%;
  }
  #rightPresetColumn{
    width:100%;
  }
  
  #userPresetContainer{
    height:90px;
  }

  h4{
    font-size:30px;
  }
  .userPreset,.defaultPreset{
    font-size:15px;
  } */


}

@media (max-width: 480px) {

  .slideMenu {
    position: fixed;
    top: 50%;
    width: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 500;
    backdrop-filter: blur(10px);
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    overflow: scroll;
    transform: translate(0, -50%);
  }

  #options-inner-container {
    width: 100%;
    margin: auto;
    display: block;
  }

  #leftPresetColumn {
    width: 100%;
  }

  #rightPresetColumn {
    width: 100%;
  }

}</style>