<template>
  <h1 id="actionName">{{ localMealActionName }}</h1>
</template>

<script setup>
import { ref, watch } from 'vue';
import { defineProps } from 'vue';

let localMeal = ref({});
let localMealActionName = ref('');

function setText(object) {
  if (object && object.actionName) {
    localMealActionName.value = object.actionName;
  } else {
    localMealActionName.value = ' ';
  }
}

const props = defineProps({
  meal: Object,
});

watch(() => props.meal, (newMeal) => {
  localMeal = newMeal;
  setText(localMeal)
}, { immediate: true });





</script>
  
<style scoped>
#actionName {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 25px;
  font-family: 'Bebas Neue';
  /* text-shadow: 2px 2px 2px #000000; */
}

@media (max-width: 1000px) {
  #actionName {
    font-size: 15px;
    line-height: 1;
    min-height: 15px;
  }

}
</style>
  